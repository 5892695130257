import React from 'react'
import { withRouter } from 'react-router-dom'

const LoginForm = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Email or Username</label>
                <input type="email" name="username" value={props.username} onChange={props.onChange} />
                <span className="error">{props.errors.username}</span>
            </div>
            <div className="form-input">
                <label>Password</label>
                <input type="password" name="password" value={props.password} onChange={props.onChange} />
                <span className="error">{props.errors.password}</span>
            </div>
            <div className="actions">
                <label><input type="checkbox" name="rememberMe" checked={props.rememberMe} onChange={props.onChange} />Remember me</label>
                {
                    props.loginProcessing?
                    <button disabled>Login</button>:
                    <button>Login</button>
                }
            </div>
        </form>
    )
}

export default withRouter(LoginForm)